// src/contexts/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut
} from 'firebase/auth';
import { auth, db } from '../lib/firebase';
import { doc, getDoc } from 'firebase/firestore';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userCompanyInfo, setUserCompanyInfo] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Function to fetch and process user data
  const processUserData = async (uid) => {
    const userDoc = await getDoc(doc(db, 'users', uid));
    if (userDoc.exists()) {
      const data = userDoc.data();
      setUserRole(data.role);
      setUserCompanyInfo(data.companyInfo);
      setUserData(data);
      return data;
    }
    return null;
  };

  async function login(email, password) {
    try {
      const credential = await signInWithEmailAndPassword(auth, email, password);
      await processUserData(credential.user.uid);
      return credential;
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  }

  function logout() {
    setUserRole(null);
    setUserCompanyInfo(null);
    setUserData(null);
    return signOut(auth);
  }

  // Permission checking functions
  const hasPermission = (action, resource = null) => {
    if (!userData) return false;

    // Super admin can do everything
    if (userData.role === 'ADMIN') return true;

    const isCompanyAdmin = userData.companyInfo?.companyRole === 'ADMIN';
    const isCreator = userData.role === 'CREATOR';
    const userCompanyCode = userData.companyInfo?.companyCode;

    switch (action) {
      case 'view_companies':
        return true; // All logged in users can view companies
        
      case 'manage_company':
        return isCompanyAdmin;
        
      case 'create_tap':
        return isCreator || isCompanyAdmin;
        
      case 'edit_tap':
        if (!resource) return isCreator || isCompanyAdmin;
        return (isCreator || isCompanyAdmin) && resource.companyCode === userCompanyCode;
        
      case 'delete_tap':
        if (!resource) return isCompanyAdmin;
        return isCompanyAdmin && resource.companyCode === userCompanyCode;

      default:
        return false;
    }
  };

  // User role and company helper functions
  const isAdmin = () => userData?.role === 'ADMIN';
  const isCompanyAdmin = () => userData?.companyInfo?.companyRole === 'ADMIN';
  const isCreator = () => userData?.role === 'CREATOR';
  const getUserCompanyCode = () => userData?.companyInfo?.companyCode;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await processUserData(user.uid);
      } else {
        setUserRole(null);
        setUserCompanyInfo(null);
        setUserData(null);
      }
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    userRole,
    userCompanyInfo,
    userData,
    login,
    logout,
    hasPermission,
    isAdmin,
    isCompanyAdmin,
    isCreator,
    getUserCompanyCode
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}