import React, { useState } from 'react';
import { useUsers } from '../../lib/hooks/useFirebaseData';
import { useAuth } from '../../contexts/AuthContext';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../../components/ui/card';
import { Input } from '../../components/ui/input';
import { Button } from '../../components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogPortal,
} from '../../components/ui/dialog';
import { Plus, Pencil, Trash2, Search, Loader2 } from 'lucide-react';

const UserManagement = () => {
  // Update auth usage to include userData and add role checks
  const { currentUser, userRole, userData } = useAuth();
  const { users, loading, error, addUser, updateUser, deleteUser } = useUsers();

  // Add role checks
  const isAdmin = userRole === 'ADMIN';
  const isCompanyAdmin = userData?.companyInfo?.companyRole === 'ADMIN';

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [deletingUser, setDeletingUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newUser, setNewUser] = useState({
    name: '',
    email: '',
    role: 'USER',
    companyInfo: {
      companyCode: userData?.companyInfo?.companyCode,
      companyRole: 'EMPLOYEE',
      jobType: ''
    }
  });

  const RoleSelect = ({ value, onChange, disabled }) => (
    <select
      className="px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
    >
      <option value="USER">User</option>
      {isAdmin && <option value="CREATOR">Creator</option>}
      {isAdmin && <option value="ADMIN">Admin</option>}
    </select>
  );

  const CompanyRoleSelect = ({ value, onChange, disabled }) => (
    <select
      className="px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
    >
      <option value="EMPLOYEE">Employee</option>
      {(isAdmin || isCompanyAdmin) && <option value="ADMIN">Company Admin</option>}
    </select>
  );

  const filteredUsers = users?.filter(
    (user) =>
      user.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email?.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];

  const handleAddUser = async () => {
    try {
      setIsSubmitting(true);
      console.log('Attempting to add user:', newUser);
      await addUser(newUser);
      setIsAddDialogOpen(false);
      setNewUser({ name: '', email: '', role: 'user' });
    } catch (err) {
      console.error('Error adding user:', err);
      alert(`Error adding user: ${err.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditUser = async (user) => {
    try {
      setIsSubmitting(true);
      console.log('Attempting to edit user:', user);
      await updateUser(user.id, {
        name: user.name,
        email: user.email,
        role: user.role,
        companyInfo: {
          companyCode: user.companyInfo?.companyCode || userData?.companyInfo?.companyCode,
          companyRole: user.companyInfo?.companyRole || 'EMPLOYEE',
          jobType: user.companyInfo?.jobType || ''
        }
      });
      setIsEditDialogOpen(false);
      setEditingUser(null);
    } catch (err) {
      console.error('Error updating user:', err);
      alert(`Error updating user: ${err.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleDeleteUser = async (id) => {
    try {
      setIsSubmitting(true);
      console.log('Attempting to delete user:', id);
      await deleteUser(id);
      setIsDeleteDialogOpen(false);
      setDeletingUser(null);
    } catch (err) {
      console.error('Error deleting user:', err);
      alert(`Error deleting user: ${err.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-6">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Users ({filteredUsers.length})</CardTitle>
          <div className="flex space-x-4">
            <div className="relative">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
              <Input
                type="search"
                placeholder="Search users..."
                className="pl-8 w-64"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <Dialog open={isAddDialogOpen} onOpenChange={setIsAddDialogOpen}>
              <DialogTrigger asChild>
                <Button>
                  <Plus className="w-4 h-4 mr-2" />
                  Add User
                </Button>
              </DialogTrigger>
              <DialogPortal>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Add New User</DialogTitle>
                    <DialogDescription>
                      Add a new user to the platform
                    </DialogDescription>
                  </DialogHeader>
                  <div className="grid gap-4 py-4">
    <Input
      placeholder="Name"
      value={newUser.name}
      onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
    />
    <Input
      placeholder="Email"
      value={newUser.email}
      onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
    />
    <RoleSelect
      value={newUser.role}
      onChange={(value) => setNewUser({ ...newUser, role: value })}
      disabled={!isAdmin}
    />
    <CompanyRoleSelect
      value={newUser.companyInfo.companyRole}
      onChange={(value) => setNewUser({
        ...newUser,
        companyInfo: { ...newUser.companyInfo, companyRole: value }
      })}
      disabled={!isAdmin && !isCompanyAdmin}
    />
  </div>
                  <DialogFooter>
                    <Button onClick={handleAddUser} disabled={isSubmitting}>
                      {isSubmitting ? (
                        <Loader2 className="h-4 w-4 animate-spin mr-2" />
                      ) : (
                        <Plus className="h-4 w-4 mr-2" />
                      )}
                      Add User
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </DialogPortal>
            </Dialog>
          </div>
        </CardHeader>
        <CardContent>
          {filteredUsers.length === 0 ? (
            <div className="text-center py-6 text-gray-500">
              No users found. Add your first user to get started!
            </div>
          ) : (
            // In your UserManagement component, update the table section:

<div className="relative overflow-x-auto">
  <table className="w-full text-left">
    <thead className="bg-gray-50 text-gray-600 text-sm">
      <tr>
        <th className="p-4 font-medium">Name</th>
        <th className="p-4 font-medium">Email</th>
        <th className="p-4 font-medium">Role</th>
        <th className="p-4 font-medium">Company Code</th>
        <th className="p-4 font-medium">Job Type</th>
        <th className="p-4 font-medium w-32">Actions</th>
      </tr>
    </thead>
    <tbody className="divide-y divide-gray-200">
      {filteredUsers.map((user) => (
        <tr key={user.id} className="bg-white">
          <td className="p-4">{user.name}</td>
          <td className="p-4">{user.email}</td>
          <td className="p-4 capitalize">{user.role}</td>
          <td className="p-4">{user.companyInfo?.companyCode || '-'}</td>
          <td className="p-4">{user.companyInfo?.jobType || '-'}</td>
          <td className="p-4">
            <div className="flex space-x-2">
              <Button
                variant="outline"
                size="sm"
                onClick={() => {
                  setEditingUser(user);
                  setIsEditDialogOpen(true);
                }}
                disabled={isSubmitting}
              >
                <Pencil className="w-4 h-4" />
              </Button>
              <Button
                variant="destructive"
                size="sm"
                onClick={() => {
                  setDeletingUser(user);
                  setIsDeleteDialogOpen(true);
                }}
                disabled={isSubmitting}
              >
                <Trash2 className="w-4 h-4" />
              </Button>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>
          )}
        </CardContent>
      </Card>

      {/* Edit Dialog */}
      {/* Edit Dialog */}
<Dialog 
  open={isEditDialogOpen} 
  onOpenChange={(open) => {
    setIsEditDialogOpen(open);
    if (!open) setEditingUser(null);
  }}
>
  <DialogContent>
    <DialogHeader>
      <DialogTitle>Edit User</DialogTitle>
      <DialogDescription>
        Make changes to the user details
      </DialogDescription>
    </DialogHeader>
    <div className="grid gap-4 py-4">
      <div className="space-y-2">
        <label className="text-sm font-medium">Name</label>
        <Input
          placeholder="Name"
          value={editingUser?.name || ''}
          onChange={(e) => setEditingUser({
            ...editingUser,
            name: e.target.value
          })}
        />
      </div>

      <div className="space-y-2">
        <label className="text-sm font-medium">Email</label>
        <Input
          placeholder="Email"
          value={editingUser?.email || ''}
          onChange={(e) => setEditingUser({
            ...editingUser,
            email: e.target.value
          })}
        />
      </div>

      <div className="space-y-2">
        <label className="text-sm font-medium">Platform Role</label>
        <select
          className="w-full px-3 py-2 rounded-md border border-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-950"
          value={editingUser?.role || 'USER'}
          onChange={(e) => setEditingUser({
            ...editingUser,
            role: e.target.value
          })}
          disabled={!isAdmin}
        >
          <option value="USER">User</option>
          <option value="CREATOR">Creator</option>
          {isAdmin && <option value="ADMIN">Admin</option>}
        </select>
      </div>

      <div className="space-y-2">
        <label className="text-sm font-medium">Company Role</label>
        <select
          className="w-full px-3 py-2 rounded-md border border-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-950"
          value={editingUser?.companyInfo?.companyRole || 'EMPLOYEE'}
          onChange={(e) => setEditingUser({
            ...editingUser,
            companyInfo: { 
              ...editingUser.companyInfo, 
              companyRole: e.target.value 
            }
          })}
          disabled={!isAdmin && !isCompanyAdmin}
        >
          <option value="EMPLOYEE">Employee</option>
          {(isAdmin || isCompanyAdmin) && <option value="ADMIN">Company Admin</option>}
        </select>
      </div>

      <div className="space-y-2">
        <label className="text-sm font-medium">Job Type</label>
        <Input
          placeholder="Job Type"
          value={editingUser?.companyInfo?.jobType || ''}
          onChange={(e) => setEditingUser({
            ...editingUser,
            companyInfo: {
              ...editingUser.companyInfo,
              jobType: e.target.value
            }
          })}
        />
      </div>
    </div>
    <DialogFooter>
      <Button
        variant="outline"
        onClick={() => {
          setIsEditDialogOpen(false);
          setEditingUser(null);
        }}
        disabled={isSubmitting}
      >
        Cancel
      </Button>
      <Button 
        onClick={() => handleEditUser(editingUser)}
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          <Loader2 className="h-4 w-4 animate-spin mr-2" />
        ) : (
          'Save Changes'
        )}
      </Button>
    </DialogFooter>
  </DialogContent>
</Dialog>

      {/* Delete Dialog */}
      <Dialog 
        open={isDeleteDialogOpen} 
        onOpenChange={(open) => {
          setIsDeleteDialogOpen(open);
          if (!open) setDeletingUser(null);
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete User</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete {deletingUser?.name}? This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setIsDeleteDialogOpen(false);
                setDeletingUser(null);
              }}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button 
              variant="destructive"
              onClick={() => handleDeleteUser(deletingUser?.id)}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Loader2 className="h-4 w-4 animate-spin mr-2" />
              ) : (
                'Delete User'
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UserManagement;