// src/lib/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCyLnwINvdIi4tbOZ6ZVQ7109eH0pApR9Y",
  authDomain: "tap-up.firebaseapp.com",
  databaseURL: "https://tap-up-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "tap-up",
  storageBucket: "tap-up.appspot.com",
  messagingSenderId: "889175786342",
  appId: "1:889175786342:web:faf84805446a82b7f27ea7",
  measurementId: "G-V9L0ZYYKFR"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };