// src/components/dashboard/CompanyManagement.jsx
import React, { useState } from 'react';
import { useCompanies } from '../../lib/hooks/useFirebaseData';
import { useAuth } from '../../contexts/AuthContext';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogPortal,
} from "../../components/ui/dialog";
import { Plus, Pencil, Trash2, Search, Loader2 } from 'lucide-react';

const CompanyManagement = () => {
  const { currentUser, userRole } = useAuth();
  const { 
    companies, 
    loading, 
    error, 
    addCompany, 
    updateCompany, 
    deleteCompany 
  } = useCompanies();

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [editingCompany, setEditingCompany] = useState(null);
  const [deletingCompany, setDeletingCompany] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newCompany, setNewCompany] = useState({
    name: '',
    code: '',
    primaryColor: '#000000',
    logo: ''
  });

  console.log('Auth state:', { currentUser, userRole });

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6">
        <Card>
          <CardContent className="text-red-500 p-4">
            Error loading companies: {error.message}
          </CardContent>
        </Card>
      </div>
    );
  }

  const filteredCompanies = companies?.filter(company =>
    company.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    company.code?.includes(searchTerm)
  ) || [];

  const handleAddCompany = async () => {
    try {
      setIsSubmitting(true);
      console.log('Attempting to add company:', newCompany);
      const id = await addCompany({
        name: newCompany.name,
        code: newCompany.code,
        primaryColor: newCompany.primaryColor,
        logo: newCompany.logo
      });
      console.log('Company added successfully:', id);
      setIsAddDialogOpen(false);
      setNewCompany({ name: '', code: '', primaryColor: '#000000', logo: '' });
    } catch (err) {
      console.error('Error adding company:', err);
      alert(`Error adding company: ${err.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditCompany = async (company) => {
    try {
      setIsSubmitting(true);
      console.log('Attempting to edit company:', company);
      await updateCompany(company.id, {
        name: company.name,
        code: company.code,
        primaryColor: company.primaryColor,
        logo: company.logo
      });
      console.log('Company updated successfully');
      setIsEditDialogOpen(false);
      setEditingCompany(null);
    } catch (err) {
      console.error('Error updating company:', err);
      alert(`Error updating company: ${err.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteCompany = async (id) => {
    try {
      setIsSubmitting(true);
      console.log('Attempting to delete company:', id);
      await deleteCompany(id);
      console.log('Company deleted successfully');
      setIsDeleteDialogOpen(false);
      setDeletingCompany(null);
    } catch (err) {
      console.error('Error deleting company:', err);
      alert(`Error deleting company: ${err.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };


  return (
    <div className="p-6">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Companies ({filteredCompanies.length})</CardTitle>
          <div className="flex space-x-4">
            <div className="relative">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
              <Input
                type="search"
                placeholder="Search companies..."
                className="pl-8 w-64"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <Dialog open={isAddDialogOpen} onOpenChange={setIsAddDialogOpen}>
              <DialogTrigger asChild>
                <Button>
                  <Plus className="w-4 h-4 mr-2" />
                  Add Company
                </Button>
              </DialogTrigger>
              <DialogPortal>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Add New Company</DialogTitle>
                    <DialogDescription>
                      Add a new company to the platform
                    </DialogDescription>
                  </DialogHeader>
                  <div className="grid gap-4 py-4">
                    <Input
                      placeholder="Company Name"
                      value={newCompany.name}
                      onChange={(e) => setNewCompany({...newCompany, name: e.target.value})}
                    />
                    <Input
                      placeholder="Company Code"
                      value={newCompany.code}
                      onChange={(e) => setNewCompany({...newCompany, code: e.target.value})}
                    />
                    <div className="flex space-x-2 items-center">
                      <Input
                        type="color"
                        value={newCompany.primaryColor}
                        onChange={(e) => setNewCompany({...newCompany, primaryColor: e.target.value})}
                        className="w-20"
                      />
                      <span className="text-sm text-gray-500">Primary Color</span>
                    </div>
                    <Input
                      placeholder="Logo URL"
                      value={newCompany.logo}
                      onChange={(e) => setNewCompany({...newCompany, logo: e.target.value})}
                    />
                  </div>
                  <DialogFooter>
                    <Button onClick={handleAddCompany} disabled={isSubmitting}>
                      {isSubmitting ? (
                        <Loader2 className="h-4 w-4 animate-spin mr-2" />
                      ) : (
                        <Plus className="h-4 w-4 mr-2" />
                      )}
                      Add Company
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </DialogPortal>
            </Dialog>
          </div>
        </CardHeader>
        <CardContent>
          {filteredCompanies.length === 0 ? (
            <div className="text-center py-6 text-gray-500">
              No companies found. Add your first company to get started!
            </div>
          ) : (
            <div className="relative overflow-x-auto">
              <table className="w-full text-left">
                <thead className="bg-gray-50 text-gray-600 text-sm">
                  <tr>
                    <th className="p-4 font-medium">Logo</th>
                    <th className="p-4 font-medium">Name</th>
                    <th className="p-4 font-medium">Code</th>
                    <th className="p-4 font-medium">Primary Color</th>
                    <th className="p-4 font-medium">Actions</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {filteredCompanies.map((company) => (
                    <tr key={company.id} className="bg-white">
                      <td className="p-4">
                        <img
                          src={company.logo || '/api/placeholder/40/40'}
                          alt={`${company.name} logo`}
                          className="w-8 h-8 object-contain"
                        />
                      </td>
                      <td className="p-4">{company.name}</td>
                      <td className="p-4">{company.code}</td>
                      <td className="p-4">
                        <div className="flex items-center space-x-2">
                          <div 
                            className="w-4 h-4 rounded border"
                            style={{ backgroundColor: company.primaryColor }}
                          />
                          <span>{company.primaryColor}</span>
                        </div>
                      </td>
                      <td className="p-4">
                        <div className="flex space-x-2">
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              setEditingCompany(company);
                              setIsEditDialogOpen(true);
                            }}
                            disabled={isSubmitting}
                          >
                            <Pencil className="w-4 h-4" />
                          </Button>
                          <Button
                            variant="destructive"
                            size="sm"
                            onClick={() => {
                              setDeletingCompany(company);
                              setIsDeleteDialogOpen(true);
                            }}
                            disabled={isSubmitting}
                          >
                            <Trash2 className="w-4 h-4" />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </CardContent>
      </Card>

   

      {/* Edit Dialog */}
      <Dialog 
        open={isEditDialogOpen} 
        onOpenChange={(open) => {
          setIsEditDialogOpen(open);
          if (!open) setEditingCompany(null);
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Company</DialogTitle>
            <DialogDescription>
              Make changes to the company details
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <Input
              placeholder="Company Name"
              value={editingCompany?.name || ''}
              onChange={(e) => setEditingCompany({
                ...editingCompany,
                name: e.target.value
              })}
            />
            <Input
              placeholder="Company Code"
              value={editingCompany?.code || ''}
              onChange={(e) => setEditingCompany({
                ...editingCompany,
                code: e.target.value
              })}
            />
            <div className="space-y-2">
  <label className="text-sm font-medium">Primary Color</label>
  <div className="flex items-center space-x-4">
    <Input
      type="text"  // Changed from "color" to "text"
      placeholder="#ffffff"
      value={editingCompany?.primaryColor || ''}
      onChange={(e) => {
        const newColor = e.target.value;
        console.log('Color input changed:', newColor);
        setEditingCompany({
          ...editingCompany,
          primaryColor: newColor
        });
      }}
      className="w-32"
    />
    <div className="flex items-center space-x-2">
      <div 
        className="w-6 h-6 rounded border"
        style={{ backgroundColor: editingCompany?.primaryColor }}
      />
      <span className="text-sm text-gray-500">
        Preview
      </span>
    </div>
  </div>
</div>
            <Input
              placeholder="Logo URL"
              value={editingCompany?.logo || ''}
              onChange={(e) => setEditingCompany({
                ...editingCompany,
                logo: e.target.value
              })}
            />
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setIsEditDialogOpen(false);
                setEditingCompany(null);
              }}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button 
              onClick={() => handleEditCompany(editingCompany)}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Loader2 className="h-4 w-4 animate-spin mr-2" />
              ) : (
                'Save Changes'
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Delete Dialog */}
      <Dialog 
        open={isDeleteDialogOpen} 
        onOpenChange={(open) => {
          setIsDeleteDialogOpen(open);
          if (!open) setDeletingCompany(null);
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Company</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete {deletingCompany?.name}? This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setIsDeleteDialogOpen(false);
                setDeletingCompany(null);
              }}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button 
              variant="destructive"
              onClick={() => handleDeleteCompany(deletingCompany?.id)}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Loader2 className="h-4 w-4 animate-spin mr-2" />
              ) : (
                'Delete Company'
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CompanyManagement;