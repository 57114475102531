// src/components/dashboard/TapContentManager.jsx
import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd"; // Changed this line
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import { 
  Plus, 
  Video, 
  Image, 
  Trash2, 
  GripVertical, 
  Loader2, 
  HelpCircle,
  ImagePlus 
} from 'lucide-react';
import { auth, db } from "../../lib/firebase";
import { serverTimestamp } from "firebase/firestore";
import FileUpload from "../ui/FileUpload";

const TapContentManager = ({ open, onOpenChange, tap, onUpdate }) => {
  const [episodes, setEpisodes] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Change this to string or null for single accordion
  const [openEpisodeId, setOpenEpisodeId] = useState(null);

  useEffect(() => {
    if (tap?.chapters) {
      console.log("Setting episodes from tap.chapters:", tap.chapters);
      setEpisodes(tap.chapters);
      // Optionally open the first episode
      if (tap.chapters.length > 0) {
        setOpenEpisodeId(tap.chapters[0].id);
      }
    } else if (tap) {
      console.log("No chapters found in tap, initializing empty array");
      setEpisodes([]);
    }
  }, [tap]);

  const addEpisode = () => {
    const newEpisode = {
      chapterId: crypto.randomUUID(),
      name: "New Episode",
      frames: [],
      creationDate: new Date().toISOString(), // Use ISO string instead of serverTimestamp
      creatorId: auth.currentUser.uid,
      tapId: tap.id,
      thumbnail: tap.thumbnail || "",
    };
    setEpisodes([...episodes, newEpisode]);
  };

  const FRAME_TYPES = {
    PHOTO: "PHOTO",
    VIDEO: "VIDEO",
    QUESTION: "QUESTION",
    PHOTO_QUESTION: "PHOTO_QUESTION",
  };

  const addFrame = (episodeId, type) => {
    setEpisodes(
      episodes.map((episode) => {
        if (episode.chapterId === episodeId) {
          const newFrame = {
            id: crypto.randomUUID(),
            type: type.toUpperCase(),
            text: "",
            textPosition: "bottom",
            createdAt: new Date().toISOString(),
          };

          // Add type-specific fields
          switch (type) {
            case FRAME_TYPES.PHOTO:
              newFrame.image = "";
              break;
            case FRAME_TYPES.VIDEO:
              newFrame.video = "";
              break;
            case FRAME_TYPES.QUESTION:
              newFrame.question = "";
              newFrame.description = "";
              newFrame.answers = ["", "", "", ""]; // Default 4 empty answers
              newFrame.correctAnswer = 0;
              break;
            case FRAME_TYPES.PHOTO_QUESTION:
              newFrame.image = "";
              newFrame.question = "";
              newFrame.description = "";
              newFrame.answers = ["", "", "", ""]; // Default 4 empty answers
              newFrame.correctAnswer = 0;
              break;
          }

          return {
            ...episode,
            frames: [...(episode.frames || []), newFrame],
          };
        }
        return episode;
      })
    );
  };

  const updateEpisode = (episodeId, data) => {
    setEpisodes(
      episodes.map((episode) => {
        if (episode.chapterId === episodeId) {
          return {
            ...episode,
            ...data,
          };
        }
        return episode;
      })
    );
  };

  const updateFrame = (episodeId, frameId, data) => {
    setEpisodes(
      episodes.map((episode) => {
        if (episode.chapterId === episodeId) {
          return {
            ...episode,
            frames: episode.frames.map((frame) => {
              if (frame.id === frameId) {
                return {
                  ...frame,
                  ...data,
                };
              }
              return frame;
            }),
          };
        }
        return episode;
      })
    );
  };

  const deleteFrame = (episodeId, frameId) => {
    setEpisodes(
      episodes.map((episode) => {
        if (episode.chapterId === episodeId) {
          return {
            ...episode,
            frames: episode.frames.filter((frame) => frame.id !== frameId),
          };
        }
        return episode;
      })
    );
  };

  const deleteEpisode = (episodeId) => {
    setEpisodes(episodes.filter((episode) => episode.chapterId !== episodeId));
  };

  // Add this to your TapContentManager component
  const renderDebugInfo = () => (
    <div className="bg-gray-100 p-4 mb-4 rounded-lg text-sm">
      <h3 className="font-bold mb-2">Debug Info:</h3>
      <pre className="overflow-auto max-h-40">
        {JSON.stringify(
          {
            tapId: tap?.id,
            chaptersCount: tap?.chapters?.length,
            episodes: episodes,
          },
          null,
          2
        )}
      </pre>
    </div>
  );

  const handleDragEnd = (result) => {
    console.log("Drag end result:", result);

    if (!result.destination) return;

    const items = Array.from(episodes);
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    console.log("Moving episode:", {
      episode: items[sourceIndex],
      from: sourceIndex,
      to: destinationIndex,
    });

    const [reorderedItem] = items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, reorderedItem);

    console.log("Updated episodes order:", items);
    setEpisodes(items);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-4xl max-h-[90vh] flex flex-col">
        <DialogHeader>
          <DialogTitle>Manage Episodes: {tap?.name}</DialogTitle>
          <DialogDescription>
            Add and manage episodes and their content
          </DialogDescription>
        </DialogHeader>
        <div className="flex-1 overflow-y-auto pr-2">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="episodes">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Accordion
                    type="single"
                    collapsible
                    value={openEpisodeId}
                    onValueChange={setOpenEpisodeId}
                    className="space-y-4"
                  >
                    {episodes.map((episode, index) => (
                      <Draggable
                        key={episode.chapterId}
                        draggableId={episode.chapterId}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <AccordionItem
                              value={episode.chapterId}
                              className="border rounded-lg p-4"
                            >
                              <div className="flex items-center gap-2">
                                <div {...provided.dragHandleProps}>
                                  <GripVertical className="h-5 w-5 text-gray-400" />
                                </div>
                                <AccordionTrigger
                                  className="flex-1 hover:no-underline"
                                  onClick={() =>
                                    setOpenEpisodeId(episode.chapterId)
                                  }
                                >
                                  <div className="flex items-center gap-2">
                                    <span>{episode.name}</span>
                                    <span className="text-sm text-gray-500">
                                      ({episode.frames?.length || 0} frames)
                                    </span>
                                  </div>
                                </AccordionTrigger>
                                <Button
                                  variant="ghost"
                                  size="sm"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    deleteEpisode(episode.chapterId);
                                  }}
                                  className="h-8 w-8 p-0"
                                >
                                  <Trash2 className="h-4 w-4" />
                                </Button>
                              </div>
                              <AccordionContent>
                                <div className="space-y-4 pt-4">
                                  <div className="grid grid-cols-1 gap-4">
                                    <div className="space-y-2">
                                      <label className="text-sm font-medium">
                                        Episode Name
                                      </label>
                                      <Input
                                        value={episode.name}
                                        onChange={(e) =>
                                          updateEpisode(episode.chapterId, {
                                            name: e.target.value,
                                          })
                                        }
                                        placeholder="Enter episode name"
                                      />
                                    </div>

                                    <div className="space-y-2">
                                      <label className="text-sm font-medium">
                                        Episode Thumbnail
                                      </label>
                                      <FileUpload
                                        folder={`taps/${tap.id}`}
                                        accept="image/*"
                                        fileId={episode.chapterId}
                                        existingUrl={episode.thumbnail || ""}
                                        onUploadComplete={(url) => {
                                          updateEpisode(episode.chapterId, {
                                            ...episode,
                                            thumbnail: url,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="space-y-4">
                                    {episode.frames?.map(
                                      (frame, frameIndex) => (
                                        <div
                                          key={frame.id}
                                          className="flex items-start gap-2 p-2 bg-gray-50 rounded-lg"
                                        >
                                          <div className="flex-1 space-y-2">
                                            <div className="flex items-center gap-2">
                                              {frame.type ===
                                              FRAME_TYPES.VIDEO ? (
                                                <Video className="h-4 w-4" />
                                              ) : frame.type ===
                                                FRAME_TYPES.QUESTION ? (
                                                <HelpCircle className="h-4 w-4" />
                                              ) : (
                                                <Image className="h-4 w-4" />
                                              )}
                                              <span className="text-sm font-medium">
                                                {frame.type} Frame
                                              </span>
                                            </div>

                                            {/* Frame type specific fields */}
                                            {(frame.type ===
                                              FRAME_TYPES.PHOTO ||
                                              frame.type ===
                                                FRAME_TYPES.PHOTO_QUESTION) && (
                                              <FileUpload
                                                folder={`frames/${frame.id}`}
                                                accept="image/*"
                                                fileId={frame.id}
                                                existingUrl={frame.image}
                                                onUploadComplete={(url) =>
                                                  updateFrame(
                                                    episode.chapterId,
                                                    frame.id,
                                                    { image: url }
                                                  )
                                                }
                                              />
                                            )}

                                            {frame.type ===
                                              FRAME_TYPES.VIDEO && (
                                              <FileUpload
                                                folder={`frames/${frame.id}`}
                                                accept="video/*"
                                                fileId={frame.id}
                                                existingUrl={frame.video}
                                                onUploadComplete={(url) =>
                                                  updateFrame(
                                                    episode.chapterId,
                                                    frame.id,
                                                    { video: url }
                                                  )
                                                }
                                              />
                                            )}

                                            {/* Question fields */}
                                            {(frame.type ===
                                              FRAME_TYPES.QUESTION ||
                                              frame.type ===
                                                FRAME_TYPES.PHOTO_QUESTION) && (
                                              <div className="space-y-4">
                                                <Input
                                                  value={frame.question || ""}
                                                  onChange={(e) =>
                                                    updateFrame(
                                                      episode.chapterId,
                                                      frame.id,
                                                      {
                                                        question:
                                                          e.target.value,
                                                      }
                                                    )
                                                  }
                                                  placeholder="Question text"
                                                />

                                                <Input
                                                  value={
                                                    frame.description || ""
                                                  }
                                                  onChange={(e) =>
                                                    updateFrame(
                                                      episode.chapterId,
                                                      frame.id,
                                                      {
                                                        description:
                                                          e.target.value,
                                                      }
                                                    )
                                                  }
                                                  placeholder="Question description (optional)"
                                                />

                                                <div className="space-y-2">
                                                  <label className="text-sm font-medium">
                                                    Answers
                                                  </label>
                                                  {frame.answers?.map(
                                                    (answer, index) => (
                                                      <div
                                                        key={index}
                                                        className="flex items-center gap-2"
                                                      >
                                                        <Input
                                                          value={answer}
                                                          onChange={(e) => {
                                                            const newAnswers = [
                                                              ...(frame.answers ||
                                                                []),
                                                            ];
                                                            newAnswers[index] =
                                                              e.target.value;
                                                            updateFrame(
                                                              episode.chapterId,
                                                              frame.id,
                                                              {
                                                                answers:
                                                                  newAnswers,
                                                              }
                                                            );
                                                          }}
                                                          placeholder={`Answer ${
                                                            index + 1
                                                          }`}
                                                        />
                                                        <input
                                                          type="radio"
                                                          checked={
                                                            frame.correctAnswer ===
                                                            index
                                                          }
                                                          onChange={() =>
                                                            updateFrame(
                                                              episode.chapterId,
                                                              frame.id,
                                                              {
                                                                correctAnswer:
                                                                  index,
                                                              }
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                            )}

                                            {/* Regular frame fields (text/position) for non-question types */}
                                            {(frame.type ===
                                              FRAME_TYPES.PHOTO ||
                                              frame.type ===
                                                FRAME_TYPES.VIDEO) && (
                                              <>
                                                <Input
                                                  value={frame.text || ""}
                                                  onChange={(e) =>
                                                    updateFrame(
                                                      episode.chapterId,
                                                      frame.id,
                                                      {
                                                        text: e.target.value,
                                                      }
                                                    )
                                                  }
                                                  placeholder="Text overlay (optional)"
                                                />
                                                <select
                                                  value={
                                                    frame.textPosition ||
                                                    "bottom"
                                                  }
                                                  onChange={(e) =>
                                                    updateFrame(
                                                      episode.chapterId,
                                                      frame.id,
                                                      {
                                                        textPosition:
                                                          e.target.value,
                                                      }
                                                    )
                                                  }
                                                  className="w-full px-3 py-2 rounded-md border border-gray-200"
                                                >
                                                  <option value="top">
                                                    Text at Top
                                                  </option>
                                                  <option value="bottom">
                                                    Text at Bottom
                                                  </option>
                                                </select>
                                              </>
                                            )}

                                            <Button
                                              variant="ghost"
                                              size="sm"
                                              onClick={() =>
                                                deleteFrame(
                                                  episode.chapterId,
                                                  frame.id
                                                )
                                              }
                                              className="mt-2"
                                            >
                                              <Trash2 className="h-4 w-4 mr-2" />
                                              Remove Frame
                                            </Button>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>

                                  <div className="flex gap-2">
                                    <Button
                                      variant="outline"
                                      size="sm"
                                      onClick={() =>
                                        addFrame(
                                          episode.chapterId,
                                          FRAME_TYPES.PHOTO
                                        )
                                      }
                                    >
                                      <Image className="h-4 w-4 mr-2" />
                                      Add Photo
                                    </Button>
                                    <Button
                                      variant="outline"
                                      size="sm"
                                      onClick={() =>
                                        addFrame(
                                          episode.chapterId,
                                          FRAME_TYPES.VIDEO
                                        )
                                      }
                                    >
                                      <Video className="h-4 w-4 mr-2" />
                                      Add Video
                                    </Button>
                                    <Button
                                      variant="outline"
                                      size="sm"
                                      onClick={() =>
                                        addFrame(
                                          episode.chapterId,
                                          FRAME_TYPES.QUESTION
                                        )
                                      }
                                    >
                                      <HelpCircle className="h-4 w-4 mr-2" />
                                      Add Question
                                    </Button>
                                    <Button
                                      variant="outline"
                                      size="sm"
                                      onClick={() =>
                                        addFrame(
                                          episode.chapterId,
                                          FRAME_TYPES.PHOTO_QUESTION
                                        )
                                      }
                                    >
                                      <ImagePlus className="h-4 w-4 mr-2" />
                                      Add Photo Question
                                    </Button>
                                  </div>
                                </div>
                              </AccordionContent>
                            </AccordionItem>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Accordion>
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <Button onClick={addEpisode} className="mt-4">
            <Plus className="h-4 w-4 mr-2" />
            Add Episode
          </Button>
        </div>
        <DialogFooter className="flex-shrink-0 pt-4">
          <Button
            variant="outline"
            onClick={() => onOpenChange(false)}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setIsSubmitting(true);
              onUpdate(episodes)
                .catch(console.error)
                .finally(() => setIsSubmitting(false));
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <Loader2 className="h-4 w-4 mr-2 animate-spin" />
            ) : (
              "Save Changes"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default TapContentManager;
