// src/components/ui/FileUpload.jsx
import React, { useState, useEffect } from 'react';
import { Button } from "./button";
import { Input } from "./input";
import { Loader2, Upload } from "lucide-react";
import { storage } from '../../lib/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const FileUpload = ({ 
  onUploadComplete, 
  folder, 
  accept = "image/*,video/*", 
  fileId,
  existingUrl = '',
  showPreview = true 
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [previewUrl, setPreviewUrl] = useState(existingUrl);

  // Update preview if existingUrl changes
  useEffect(() => {
    if (existingUrl) {
      setPreviewUrl(existingUrl);
    }
  }, [existingUrl]);

  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setIsUploading(true);
      setUploadProgress(0);

      // Create a unique file ID if not provided
      const uniqueId = fileId || crypto.randomUUID();
      
      // Get file extension
      const extension = file.name.split('.').pop();

      // Create storage reference
      const storageRef = ref(storage, `${folder}/${uniqueId}.${extension}`);

      // Upload file
      const snapshot = await uploadBytes(storageRef, file);
      
      // Get download URL
      const downloadUrl = await getDownloadURL(snapshot.ref);
      
      // Update preview
      setPreviewUrl(downloadUrl);

      // Call completion handler with URL
      onUploadComplete(downloadUrl);
      
      setIsUploading(false);
      setUploadProgress(100);
    } catch (error) {
      console.error('Upload error:', error);
      setIsUploading(false);
      alert('Error uploading file. Please try again.');
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4">
        <Input
          type="file"
          accept={accept}
          onChange={handleFileUpload}
          disabled={isUploading}
          className="max-w-xs"
        />
        {isUploading && (
          <div className="flex items-center gap-2">
            <Loader2 className="h-4 w-4 animate-spin" />
            <span className="text-sm text-gray-500">
              Uploading... {uploadProgress}%
            </span>
          </div>
        )}
      </div>

      {showPreview && previewUrl && (
        <div className="relative aspect-video w-full max-w-md rounded-lg overflow-hidden border">
          {accept.includes('video') ? (
            <video 
              src={previewUrl} 
              controls 
              className="w-full h-full object-cover"
            />
          ) : (
            <img 
              src={previewUrl} 
              alt="Preview" 
              className="w-full h-full object-cover"
              onError={(e) => e.target.src = '/api/placeholder/400/225'}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default FileUpload;