// src/components/dashboard/TapsManagement.jsx
import React, { useState, useEffect } from 'react';
import { useTopics, useCompanies, useTaps } from '../../lib/hooks/useFirebaseData';
import { useAuth } from '../../contexts/AuthContext';
import { auth } from '../../lib/firebase';
import { serverTimestamp } from 'firebase/firestore';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogPortal,
} from "../../components/ui/dialog";
import { Plus, Pencil, Trash2, Search, Loader2, PlayCircle, LayoutList } from 'lucide-react';
import TapContentManager from './TapContentManager';
import FileUpload from '../ui/FileUpload';  // adjust the path as needed

// Form Field Components
const FormField = ({ label, children }) => (
  <div className="space-y-2">
    <label className="text-sm font-medium">{label}</label>
    {children}
  </div>
);

const TapsManagement = () => {
  const { currentUser, userRole, userData } = useAuth();
  const { taps, loading, error, addTap, updateTap, deleteTap } = useTaps();
  const { companies } = useCompanies();
  const { topics } = useTopics();

  // Permission helper functions
  const userCompanyCode = userData?.companyInfo?.companyCode;
  const isAdmin = userRole === 'ADMIN';
  const isCompanyAdmin = userData?.companyInfo?.companyRole === 'ADMIN';
  const isCreator = userRole === 'CREATOR';

  const canManageTap = (tap) => {
    if (!tap) return false; // Safety check for null/undefined
    if (isAdmin) return true;
    if (isCompanyAdmin && tap.companyCode === userCompanyCode) return true;
    if (userRole === 'CREATOR' && tap.companyCode === userCompanyCode) return true;
    return false;
  };

  const canAddTap = () => {
    return isAdmin || isCompanyAdmin || userRole === 'CREATOR';
  };
  
  const canDeleteTap = (tap) => {
    if (!tap) return false; // Safety check for null/undefined
    if (isAdmin) return true;
    if (isCompanyAdmin && tap.companyCode === userCompanyCode) return true;
    if (userRole === 'CREATOR' && tap.companyCode === userCompanyCode) return true;
    return false;
  };

  // Component state
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isContentDialogOpen, setIsContentDialogOpen] = useState(false);
  const [editingTap, setEditingTap] = useState(null);
  const [activeTap, setActiveTap] = useState(null);
  const [deletingTap, setDeletingTap] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newTap, setNewTap] = useState({
    name: '',
    fullName: '',
    description: '',
    companyCode: isAdmin ? '' : userCompanyCode,
    topicId: '',
    thumbnail: ''
  });

  // Custom Select Components with Permission Handling
  const CompanySelect = ({ value, onChange, disabled }) => {
    const { companies, loading } = useCompanies();
    
    // If user is not admin, they can only select their company
    if (!isAdmin) {
      const userCompany = companies.find(c => c.code === userCompanyCode);
      return (
        <select 
          className="w-full px-3 py-2 rounded-md border border-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-950"
          value={userCompanyCode}
          disabled={true}
        >
          <option value={userCompanyCode}>
            {userCompany?.name || userCompanyCode}
          </option>
        </select>
      );
    }
    
    return (
      <select 
      className="w-full px-3 py-2 rounded-md border border-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-950"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled || loading}
    >
      <option value="">Select a company</option>
      {companies.map((company) => (
        <option key={company.id} value={company.code}>
          {company.name} ({company.code})
        </option>
      ))}
    </select>
    );
  };

  const TopicSelect = ({ value, onChange, disabled }) => {
    const { topics, loading } = useTopics();
    
    return (
      <select 
        className="w-full px-3 py-2 rounded-md border border-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-950"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled || loading}
      >
        <option value="">Select a topic</option>
        {topics.map((topic) => (
          <option key={topic.id} value={topic.id}>
            {topic.name}
          </option>
        ))}
      </select>
    );
  };

  // Helper functions
  const getCompanyName = (companyCode) => {
    const company = companies.find(c => c.code === companyCode);
    return company?.name || companyCode;
  };

  const getTopicName = (topicId) => {
    const topic = topics.find(t => t.id === topicId);
    return topic?.name || topicId;
  };

  // Handlers
  // In TapsManagement.jsx, update the handleUpdateTapContent function:

// In TapsManagement.jsx:

const handleUpdateTapContent = async (episodes) => {
  if (!canManageTap(activeTap)) {
    alert('You do not have permission to update this tap');
    return;
  }

  try {
    setIsSubmitting(true);
    
    console.log('Initial episodes:', JSON.stringify(episodes, null, 2));

    const cleanedChapters = episodes.map(chapter => {
      console.log('Processing chapter:', JSON.stringify(chapter, null, 2));

      // Create a clean chapter object with all required fields
      const cleanChapter = {
        chapterId: chapter.chapterId || crypto.randomUUID(),
        name: chapter.name || 'Untitled Episode',
        creatorId: chapter.creatorId || auth.currentUser.uid,
        creationDate: chapter.creationDate || new Date().toISOString(),
        thumbnail: chapter.thumbnail || '',
        frames: []
      };

      // Clean and process frames if they exist
      if (Array.isArray(chapter.frames)) {
        cleanChapter.frames = chapter.frames.map(frame => {
          console.log('Processing frame:', JSON.stringify(frame, null, 2));

          // Base frame object
          const cleanFrame = {
            id: frame.id || crypto.randomUUID(),
            type: frame.type || 'PHOTO',
            createdAt: frame.createdAt || new Date().toISOString()
          };

          // Add type-specific fields based on frame type
          switch (frame.type) {
            case 'PHOTO':
              cleanFrame.image = frame.image || '';
              cleanFrame.text = frame.text || '';
              cleanFrame.textPosition = frame.textPosition || 'bottom';
              break;
            case 'VIDEO':
              cleanFrame.video = frame.video || '';
              cleanFrame.text = frame.text || '';
              cleanFrame.textPosition = frame.textPosition || 'bottom';
              break;
            case 'QUESTION':
              cleanFrame.question = frame.question || '';
              cleanFrame.description = frame.description || '';
              cleanFrame.answers = frame.answers || ['', '', '', ''];
              cleanFrame.correctAnswer = frame.correctAnswer ?? 0;
              break;
            case 'PHOTO_QUESTION':
              cleanFrame.image = frame.image || '';
              cleanFrame.question = frame.question || '';
              cleanFrame.description = frame.description || '';
              cleanFrame.answers = frame.answers || ['', '', '', ''];
              cleanFrame.correctAnswer = frame.correctAnswer ?? 0;
              break;
            default:
              // For unknown types, keep all existing fields
              Object.assign(cleanFrame, frame);
          }

          console.log('Cleaned frame:', JSON.stringify(cleanFrame, null, 2));
          return cleanFrame;
        });
      }

      console.log('Cleaned chapter:', JSON.stringify(cleanChapter, null, 2));
      return cleanChapter;
    });

    const updateData = {
      name: activeTap.name || '',
      fullName: activeTap.fullName || '',
      description: activeTap.description || '',
      companyCode: activeTap.companyCode || '',
      topicId: activeTap.topicId || '',
      thumbnail: activeTap.thumbnail || '',
      chapters: cleanedChapters,
      updatedAt: serverTimestamp(),
      updatedBy: auth.currentUser.uid
    };

    console.log('Update data being sent to Firebase:', JSON.stringify(updateData, null, 2));

    await updateTap(activeTap.id, updateData);
    setIsContentDialogOpen(false);
    setActiveTap(null);
  } catch (err) {
    console.error('Error updating tap content:', err);
    alert(`Error updating tap content: ${err.message}`);
  } finally {
    setIsSubmitting(false);
  }
};

  // Modified version of handleAddTap and handleEditTap
const handleAddTap = async () => {
  if (!canAddTap()) {
    alert('You do not have permission to add taps');
    return;
  }

  try {
    setIsSubmitting(true);
    const tapData = {
      ...newTap,
      companyCode: isAdmin ? newTap.companyCode : userCompanyCode,
      creatorId: currentUser.uid, // Changed from createdBy to match data model
      createdAt: new Date(),
      chapters: [], // Initialize empty chapters array
      updatedAt: new Date(),
      updatedBy: currentUser.uid
    };
    
    // For CREATOR role, force the company code to their assigned company
    if (userRole === 'CREATOR') {
      tapData.companyCode = userCompanyCode;
    }
    
    console.log('Attempting to add tap:', tapData);
    await addTap(tapData);
    setIsAddDialogOpen(false);
    setNewTap({
      name: '',
      fullName: '',
      description: '',
      companyCode: isAdmin ? '' : userCompanyCode,
      topicId: '',
      thumbnail: ''
    });
  } catch (err) {
    console.error('Error adding tap:', err);
    alert(`Error adding tap: ${err.message}`);
  } finally {
    setIsSubmitting(false);
  }
};


const handleEditTap = async (tap) => {
  if (!canManageTap(tap)) {
    alert('You do not have permission to edit this tap');
    return;
  }

  try {
    setIsSubmitting(true);
    console.log('Attempting to edit tap:', tap);
    
    // Clean the data before sending
    const updateData = {
      name: tap.name || '',
      fullName: tap.fullName || '',
      description: tap.description || '',
      companyCode: isAdmin ? tap.companyCode : userCompanyCode,
      topicId: tap.topicId || '',
      thumbnail: tap.thumbnail || '',
      chapters: tap.chapters?.map(chapter => ({
        chapterId: chapter.chapterId,
        name: chapter.name || '',
        creatorId: chapter.creatorId,
        creationDate: chapter.creationDate,
        thumbnail: chapter.thumbnail || '',
        frames: chapter.frames?.map(frame => ({
          id: frame.id,
          type: frame.type,
          text: frame.text || '',
          textPosition: frame.textPosition || 'bottom',
          createdAt: frame.createdAt,
          ...(frame.type === 'PHOTO' ? { image: frame.image || '' } : { video: frame.video || '' })
        })) || []
      })) || [],
      updatedBy: currentUser.uid
    };

    console.log('Update data:', updateData);
    
    await updateTap(tap.id, updateData);
    setIsEditDialogOpen(false);
    setEditingTap(null);
  } catch (err) {
    console.error('Error updating tap:', err);
    alert(`Error updating tap: ${err.message}`);
  } finally {
    setIsSubmitting(false);
  }
};

  const handleDeleteTap = async (id) => {
    if (!canDeleteTap(deletingTap)) {
      alert('You do not have permission to delete this tap');
      return;
    }

    try {
      setIsSubmitting(true);
      console.log('Attempting to delete tap:', id);
      await deleteTap(id);
      setIsDeleteDialogOpen(false);
      setDeletingTap(null);
    } catch (err) {
      console.error('Error deleting tap:', err);
      alert(`Error deleting tap: ${err.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    console.log('Current user role:', userRole);
    console.log('Company code:', userCompanyCode);
    console.log('Is admin:', isAdmin);
    console.log('Is company admin:', isCompanyAdmin);
    console.log('User data:', userData);
  }, [userRole, userCompanyCode, isAdmin, isCompanyAdmin, userData]);
  // Loading and error states
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6">
        <Card>
          <CardContent className="text-red-500 p-4">
            Error loading taps: {error.message}
          </CardContent>
        </Card>
      </div>
    );
  }

  // Filter taps based on search and permissions
  const filteredTaps = taps?.filter(tap => {
    // First apply the search filter
    const matchesSearch = 
      tap.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      tap.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      tap.companyCode?.includes(searchTerm);
  
    // Then apply permission filter
    if (isAdmin) return matchesSearch;
    
    // Company admins and creators can only see their company's taps
    if (userRole === 'CREATOR' || isCompanyAdmin) {
      return matchesSearch && tap.companyCode === userCompanyCode;
    }
    
    // Regular users can only see their company's taps
    return matchesSearch && tap.companyCode === userCompanyCode;
  }) || [];

  // Render component
  return (
    <div className="p-6">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Taps ({filteredTaps.length})</CardTitle>
          <div className="flex space-x-4">
            <div className="relative">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
              <Input
                type="search"
                placeholder="Search taps..."
                className="pl-8 w-64"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {canAddTap() && (
              <Dialog open={isAddDialogOpen} onOpenChange={setIsAddDialogOpen}>
                <DialogTrigger asChild>
                  <Button>
                    <Plus className="w-4 h-4 mr-2" />
                    Add Tap
                  </Button>
                </DialogTrigger>
                <DialogPortal>
                  <DialogContent className="max-w-2xl max-h-[90vh] flex flex-col">


                  <DialogHeader>
                      <DialogTitle>Add New Tap</DialogTitle>
                      <DialogDescription>
                        Add a new tap to the platform
                      </DialogDescription>
                    </DialogHeader>
                    <div className="flex-1 overflow-y-auto pr-2">
                      <div className="grid gap-6 py-4">
                        <div className="grid grid-cols-2 gap-4">
                          <FormField label="Short Name">
                            <Input
                              placeholder="Enter short name"
                              value={newTap.name}
                              onChange={(e) => setNewTap({...newTap, name: e.target.value})}
                            />
                          </FormField>
                          <FormField label="Full Name">
                            <Input
                              placeholder="Enter full name"
                              value={newTap.fullName}
                              onChange={(e) => setNewTap({...newTap, fullName: e.target.value})}
                            />
                          </FormField>
                        </div>
                        <FormField label="Description">
                          <Input
                            placeholder="Enter description"
                            value={newTap.description}
                            onChange={(e) => setNewTap({...newTap, description: e.target.value})}
                          />
                        </FormField>
                        <div className="grid grid-cols-2 gap-4">
                          <FormField label="Company">
                            <CompanySelect
                              value={newTap.companyCode}
                              onChange={(value) => setNewTap({...newTap, companyCode: value})}
                              disabled={isSubmitting || !isAdmin}
                            />
                          </FormField>
                          <FormField label="Topic">
                            <TopicSelect
                              value={newTap.topicId}
                              onChange={(value) => setNewTap({...newTap, topicId: value})}
                              disabled={isSubmitting}
                            />
                          </FormField>
                        </div>
                        <FormField label="Thumbnail URL">
  <FileUpload
    folder="taps"
    accept="image/*"
    fileId={crypto.randomUUID()}
    existingUrl={newTap.thumbnail || ''}
    onUploadComplete={(url) => setNewTap({
      ...newTap,
      thumbnail: url
    })}
  />
</FormField>
                      </div>
                    </div>
                    <DialogFooter className="flex-shrink-0">
                      <Button onClick={handleAddTap} disabled={isSubmitting}>
                        {isSubmitting ? (
                          <Loader2 className="h-4 w-4 animate-spin mr-2" />
                        ) : (
                          <Plus className="h-4 w-4 mr-2" />
                        )}
                        Add Tap
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </DialogPortal>
              </Dialog>
            )}
          </div>
        </CardHeader>
        <CardContent>
          {filteredTaps.length === 0 ? (
            <div className="text-center py-6 text-gray-500">
              No taps found. {canAddTap() && 'Add your first tap to get started!'}
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {filteredTaps.map((tap) => (
                <Card key={tap.id} className="overflow-hidden">
                  <div className="aspect-video relative">
                    <img
                      src={tap.thumbnail || '/api/placeholder/400/225'}
                      alt={tap.name}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-black/50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity">
                      <PlayCircle className="h-12 w-12 text-white" />
                    </div>
                  </div>
                  <CardHeader>
                    <div className="flex items-start justify-between">
                      <div>
                        <CardTitle className="text-lg">{tap.name}</CardTitle>
                        <p className="text-sm text-gray-500">{tap.fullName}</p>
                      </div>
                      <div className="flex space-x-2">
                        {canManageTap(tap) && (
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              setActiveTap(tap);
                              setIsContentDialogOpen(true);
                            }}
                          >
                            <LayoutList className="w-4 h-4 mr-2" />
                            Episodes
                          </Button>
                        )}
                        {canManageTap(tap) && (
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              setEditingTap(tap);
                              setIsEditDialogOpen(true);
                            }}
                            disabled={isSubmitting}
                          >
                            <Pencil className="w-4 h-4" />
                          </Button>
                        )}
                        {canDeleteTap(tap) && (
                          <Button
                            variant="destructive"
                            size="sm"
                            onClick={() => {
                              setDeletingTap(tap);
                              setIsDeleteDialogOpen(true);
                            }}
                            disabled={isSubmitting}
                          >
                            <Trash2 className="w-4 h-4" />
                          </Button>
                        )}
                      </div>
                    </div>
                  </CardHeader>
                  <CardContent>
                    <p className="text-sm text-gray-600 line-clamp-2">{tap.description}</p>
                    <div className="mt-2 flex flex-wrap gap-2 items-center text-xs text-gray-500">
                      <span className="bg-gray-100 px-2 py-1 rounded">
                        Company: {getCompanyName(tap.companyCode)}
                      </span>
                      <span className="bg-gray-100 px-2 py-1 rounded">
                        Topic: {getTopicName(tap.topicId)}
                      </span>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          )}
        </CardContent>
      </Card>

      {/* Content Manager Dialog */}
      {canManageTap(activeTap) && (
        <TapContentManager
          open={isContentDialogOpen}
          onOpenChange={setIsContentDialogOpen}
          tap={activeTap}
          onUpdate={handleUpdateTapContent}
        />
      )}

      {/* Edit Dialog */}
      {canManageTap(editingTap) && (
        <Dialog 
          open={isEditDialogOpen} 
          onOpenChange={(open) => {
            setIsEditDialogOpen(open);
            if (!open) setEditingTap(null);
          }}
        >
          <DialogContent className="max-w-2xl max-h-[90vh] flex flex-col">
            <DialogHeader>
              <DialogTitle>Edit Tap</DialogTitle>
              <DialogDescription>
                Make changes to the tap details
              </DialogDescription>
            </DialogHeader>
            <div className="flex-1 overflow-y-auto pr-2">
              <div className="grid gap-6 py-4">
                <div className="grid grid-cols-2 gap-4">
                  <FormField label="Short Name">
                    <Input
                      placeholder="Enter short name"
                      value={editingTap?.name || ''}
                      onChange={(e) => setEditingTap({
                        ...editingTap,
                        name: e.target.value
                      })}
                    />
                  </FormField>
                  <FormField label="Full Name">
                    <Input
                      placeholder="Enter full name"
                      value={editingTap?.fullName || ''}
                      onChange={(e) => setEditingTap({
                        ...editingTap,
                        fullName: e.target.value
                      })}
                    />
                  </FormField>
                </div>
                <FormField label="Description">
                  <Input
                    placeholder="Enter description"
                    value={editingTap?.description || ''}
                    onChange={(e) => setEditingTap({
                      ...editingTap,
                      description: e.target.value
                    })}
                  />
                </FormField>
                <div className="grid grid-cols-2 gap-4">
                  <FormField label="Company">
                    <CompanySelect
                      value={editingTap?.companyCode || ''}
                      onChange={(value) => setEditingTap({
                        ...editingTap,
                        companyCode: value
                      })}
                      disabled={isSubmitting || !isAdmin}
                    />
                  </FormField>
                  <FormField label="Topic">
                    <TopicSelect
                      value={editingTap?.topicId || ''}
                      onChange={(value) => setEditingTap({
                        ...editingTap,
                        topicId: value
                      })}
                      disabled={isSubmitting}
                    />
                  </FormField>
                </div>
                <FormField label="Thumbnail URL">
  <FileUpload
    folder={`taps/${editingTap?.id}`}
    accept="image/*"
    fileId={editingTap?.id}
    existingUrl={editingTap?.thumbnail || ''}
    onUploadComplete={(url) => setEditingTap({
      ...editingTap,
      thumbnail: url
    })}
  />
</FormField>
              </div>
            </div>
            <DialogFooter className="flex-shrink-0">
              <Button
                variant="outline"
                onClick={() => {
                  setIsEditDialogOpen(false);
                  setEditingTap(null);
                }}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button 
                onClick={() => handleEditTap(editingTap)}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <Loader2 className="h-4 w-4 animate-spin mr-2" />
                ) : (
                  'Save Changes'
                )}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}

      {/* Delete Dialog */}
      {canDeleteTap(deletingTap) && (
        <Dialog 
          open={isDeleteDialogOpen} 
          onOpenChange={(open) => {
            setIsDeleteDialogOpen(open);
            if (!open) setDeletingTap(null);
          }}
        >
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Delete Tap</DialogTitle>
              <DialogDescription>
                Are you sure you want to delete {deletingTap?.name}? This action cannot be undone 
                and will remove all associated chapters and frames.
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button
                variant="outline"
                onClick={() => {
                  setIsDeleteDialogOpen(false);
                  setDeletingTap(null);
                }}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button 
                variant="destructive"
                onClick={() => handleDeleteTap(deletingTap?.id)}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <Loader2 className="h-4 w-4 animate-spin mr-2" />
                ) : (
                  'Delete'
                )}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default TapsManagement;