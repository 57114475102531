// src/App.js
import React, { useEffect } from 'react';
import { 
  BrowserRouter as Router, 
  Routes, 
  Route, 
  Navigate, 
  useNavigate, 
  useLocation 
} from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import DashboardLayout from './components/dashboard/DashboardLayout';
import DashboardOverview from './components/dashboard/DashboardOverview';
import CompanyManagement from './components/dashboard/CompanyManagement';
import TapsManagement from './components/dashboard/TapsManagement';
import TopicsManagement from './components/dashboard/TopicsManagement';
import Login from './components/Login';
import UserManagement from './components/dashboard/UserManagement';
import Settings from './components/dashboard/Settings';


// PrivateRoute component
const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();
  const location = useLocation();
  
  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

// PermissionRoute component
const PermissionRoute = ({ children, requiredRole, requiredCompanyRole }) => {
  const { userRole, userData } = useAuth();
  const navigate = useNavigate();

  const hasPermission = () => {
    if (requiredRole && userRole !== requiredRole && userRole !== 'ADMIN') {
      return false;
    }
    if (requiredCompanyRole && 
        userData?.companyInfo?.companyRole !== requiredCompanyRole && 
        userRole !== 'ADMIN') {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!hasPermission()) {
      navigate('/dashboard');
    }
  }, [userRole, userData, navigate]);

  if (!hasPermission()) {
    return null;
  }

  return children;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <DashboardLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<DashboardOverview />} />
            <Route path="dashboard" element={<DashboardOverview />} />
            
            {/* Only super admin can access companies */}
            <Route 
              path="companies" 
              element={
                <PermissionRoute requiredRole="ADMIN">
                  <CompanyManagement />
                </PermissionRoute>
              } 
            />
            
            {/* Admin and company admin can access topics */}
            <Route 
              path="topics" 
              element={
                <PermissionRoute requiredCompanyRole="ADMIN">
                  <TopicsManagement />
                </PermissionRoute>
              } 
            />
            
            {/* Everyone can access taps but with different capabilities */}
            <Route path="taps" element={<TapsManagement />} />
            
            {/* Admin and company admin can access users */}
            <Route 
              path="users" 
              element={
                <PermissionRoute requiredCompanyRole="ADMIN">
                  <UserManagement />
                </PermissionRoute>
              } 
            />
            
            {/* Admin and company admin can access settings */}
            <Route 
              path="settings" 
              element={
                <PermissionRoute requiredCompanyRole="ADMIN">
                  <Settings />
                </PermissionRoute>
              } 
            />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;