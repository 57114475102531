// src/components/dashboard/DashboardLayout.jsx
import React, { useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';  // Added this import
import { useCompanies } from '../../lib/hooks/useFirebaseData';

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Avatar, AvatarFallback, AvatarImage } from "../../components/ui/avatar";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";

import { 
  LayoutDashboard, 
  Users, 
  Building2, 
  BookOpen, 
  Video, 
  Settings,
  Menu,
  Search,
  LogOut
} from 'lucide-react';



const DashboardLayout = () => {
    const navigate = useNavigate();
    const { currentUser, userRole, userData, logout } = useAuth();
    const { companies } = useCompanies();
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [activeSection, setActiveSection] = useState('dashboard');
  
    const isAdmin = userRole === 'ADMIN';
    const isCompanyAdmin = userData?.companyInfo?.companyRole === 'ADMIN';
    const isCreator = userRole === 'CREATOR';
    const userCompanyCode = userData?.companyInfo?.companyCode;

    const getCompanyName = (companyCode) => {
        const company = companies?.find(c => c.code === companyCode);
        return company?.name || companyCode;
      };


  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };
  
    // Define menu items based on permissions
    const getMenuItems = () => {
      const items = [
        // Dashboard is visible to everyone
        {
          id: 'dashboard',
          label: 'Dashboard',
          icon: <LayoutDashboard className="w-4 h-4" />,
          show: true
        },
      ];
  
      // Companies management only for super admin
      if (isAdmin) {
        items.push({
          id: 'companies',
          label: 'Companies',
          icon: <Building2 className="w-4 h-4" />,
          show: true
        });
      }
  
      // Topics management for admin and company admin
      if (isAdmin || isCompanyAdmin) {
        items.push({
          id: 'topics',
          label: 'Topics',
          icon: <BookOpen className="w-4 h-4" />,
          show: true
        });
      }
  
      // Taps visible to everyone but with different capabilities
      items.push({
        id: 'taps',
        label: 'Taps',
        icon: <Video className="w-4 h-4" />,
        show: true
      });
  
      // Users management for admin and company admin
      if (isAdmin || isCompanyAdmin) {
        items.push({
          id: 'users',
          label: 'Users',
          icon: <Users className="w-4 h-4" />,
          show: true
        });
      }
  
      // Settings for admin and company admin
      if (isAdmin || isCompanyAdmin) {
        items.push({
          id: 'settings',
          label: 'Settings',
          icon: <Settings className="w-4 h-4" />,
          show: true
        });
      }
  
      return items;
    };
  
    const menuItems = getMenuItems();

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white border-b">
        <div className="flex items-center justify-between px-4 py-3">
          <div className="flex items-center space-x-4">
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="p-2 rounded-lg hover:bg-gray-100"
            >
              <Menu className="w-5 h-5" />
            </button>
            <h1 className="text-xl font-semibold">TapUp Dashboard</h1>
          </div>
          
          <div className="flex items-center space-x-4">
            
            
            <div className="flex items-center space-x-4">
              <div className="text-sm text-gray-600">
                <div>{userData?.fullName || userData?.name}</div>
                <div className="text-xs text-gray-400">
                  {userData?.companyInfo?.companyRole} - {userRole}
                  {!isAdmin && ` - ${getCompanyName(userCompanyCode)}`}
                </div>
              </div>
              <Avatar>
                <AvatarImage src="/api/placeholder/32/32" alt={userData?.fullName || 'User'} />
                <AvatarFallback>{userData?.fullName?.[0] || 'U'}</AvatarFallback>
              </Avatar>
              <Button
                variant="ghost"
                size="sm"
                onClick={handleLogout}
                className="text-gray-600 hover:text-gray-900"
              >
                <LogOut className="w-4 h-4 mr-2" />
                Logout
              </Button>
            </div>
          </div>
        </div>
      </header>

      <div className="flex h-[calc(100vh-64px)]">
        {/* Sidebar */}
        <aside className={`${
          isSidebarOpen ? 'w-64' : 'w-20'
        } bg-white border-r transition-all duration-300`}>
          <nav className="p-4">
            <ul className="space-y-2">
              {menuItems
                .filter(item => item.show)
                .map((item) => (
                  <li key={item.id}>
                    <button
                      onClick={() => {
                        setActiveSection(item.id);
                        navigate(`/${item.id}`);
                      }}
                      className={`w-full flex items-center space-x-3 px-4 py-2 rounded-lg transition-colors ${
                        activeSection === item.id
                          ? 'bg-gray-100 text-gray-900'
                          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                      }`}
                    >
                      {item.icon}
                      {isSidebarOpen && <span>{item.label}</span>}
                    </button>
                  </li>
                ))}
            </ul>
          </nav>
        </aside>
        {/* Main Content */}
        <main className="flex-1 overflow-auto">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;