// src/components/dashboard/TopicsManagement.jsx
import React, { useState } from 'react';
import { useTopics } from '../../lib/hooks/useFirebaseData';
import { useAuth } from '../../contexts/AuthContext';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogPortal,
} from "../../components/ui/dialog";
import { Plus, Pencil, Trash2, Search, Loader2 } from 'lucide-react';

const TopicsManagement = () => {
  const { currentUser, userRole } = useAuth();
  const { topics, loading, error, addTopic, updateTopic, deleteTopic } = useTopics();

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [editingTopic, setEditingTopic] = useState(null);
  const [deletingTopic, setDeletingTopic] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newTopic, setNewTopic] = useState({
    name: ''
  });

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6">
        <Card>
          <CardContent className="text-red-500 p-4">
            Error loading topics: {error.message}
          </CardContent>
        </Card>
      </div>
    );
  }

  const filteredTopics = topics?.filter(topic =>
    topic.name?.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];

  const handleAddTopic = async () => {
    try {
      setIsSubmitting(true);
      console.log('Attempting to add topic:', newTopic);
      await addTopic(newTopic);
      setIsAddDialogOpen(false);
      setNewTopic({ name: '' });
    } catch (err) {
      console.error('Error adding topic:', err);
      alert(`Error adding topic: ${err.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditTopic = async (topic) => {
    try {
      setIsSubmitting(true);
      console.log('Attempting to edit topic:', topic);
      await updateTopic(topic.id, {
        name: topic.name
      });
      setIsEditDialogOpen(false);
      setEditingTopic(null);
    } catch (err) {
      console.error('Error updating topic:', err);
      alert(`Error updating topic: ${err.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteTopic = async (id) => {
    try {
      setIsSubmitting(true);
      console.log('Attempting to delete topic:', id);
      await deleteTopic(id);
      setIsDeleteDialogOpen(false);
      setDeletingTopic(null);
    } catch (err) {
      console.error('Error deleting topic:', err);
      alert(`Error deleting topic: ${err.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-6">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Topics ({filteredTopics.length})</CardTitle>
          <div className="flex space-x-4">
            <div className="relative">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
              <Input
                type="search"
                placeholder="Search topics..."
                className="pl-8 w-64"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <Dialog open={isAddDialogOpen} onOpenChange={setIsAddDialogOpen}>
              <DialogTrigger asChild>
                <Button>
                  <Plus className="w-4 h-4 mr-2" />
                  Add Topic
                </Button>
              </DialogTrigger>
              <DialogPortal>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Add New Topic</DialogTitle>
                    <DialogDescription>
                      Add a new topic to the platform
                    </DialogDescription>
                  </DialogHeader>
                  <div className="grid gap-4 py-4">
                    <Input
                      placeholder="Topic Name"
                      value={newTopic.name}
                      onChange={(e) => setNewTopic({ name: e.target.value })}
                    />
                  </div>
                  <DialogFooter>
                    <Button onClick={handleAddTopic} disabled={isSubmitting}>
                      {isSubmitting ? (
                        <Loader2 className="h-4 w-4 animate-spin mr-2" />
                      ) : (
                        <Plus className="h-4 w-4 mr-2" />
                      )}
                      Add Topic
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </DialogPortal>
            </Dialog>
          </div>
        </CardHeader>
        <CardContent>
          {filteredTopics.length === 0 ? (
            <div className="text-center py-6 text-gray-500">
              No topics found. Add your first topic to get started!
            </div>
          ) : (
            <div className="relative overflow-x-auto">
              <table className="w-full text-left">
                <thead className="bg-gray-50 text-gray-600 text-sm">
                  <tr>
                    <th className="p-4 font-medium">Name</th>
                    <th className="p-4 font-medium">Created At</th>
                    <th className="p-4 font-medium w-32">Actions</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {filteredTopics.map((topic) => (
                    <tr key={topic.id} className="bg-white">
                      <td className="p-4">{topic.name}</td>
                      <td className="p-4">
                        {topic.createdAt?.toDate?.().toLocaleDateString() || 'N/A'}
                      </td>
                      <td className="p-4">
                        <div className="flex space-x-2">
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              setEditingTopic(topic);
                              setIsEditDialogOpen(true);
                            }}
                            disabled={isSubmitting}
                          >
                            <Pencil className="w-4 h-4" />
                          </Button>
                          <Button
                            variant="destructive"
                            size="sm"
                            onClick={() => {
                              setDeletingTopic(topic);
                              setIsDeleteDialogOpen(true);
                            }}
                            disabled={isSubmitting}
                          >
                            <Trash2 className="w-4 h-4" />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </CardContent>
      </Card>

      {/* Edit Dialog */}
      <Dialog 
        open={isEditDialogOpen} 
        onOpenChange={(open) => {
          setIsEditDialogOpen(open);
          if (!open) setEditingTopic(null);
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Topic</DialogTitle>
            <DialogDescription>
              Make changes to the topic details
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <Input
              placeholder="Topic Name"
              value={editingTopic?.name || ''}
              onChange={(e) => setEditingTopic({
                ...editingTopic,
                name: e.target.value
              })}
            />
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setIsEditDialogOpen(false);
                setEditingTopic(null);
              }}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button 
              onClick={() => handleEditTopic(editingTopic)}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Loader2 className="h-4 w-4 animate-spin mr-2" />
              ) : (
                'Save Changes'
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Delete Dialog */}
      <Dialog 
        open={isDeleteDialogOpen} 
        onOpenChange={(open) => {
          setIsDeleteDialogOpen(open);
          if (!open) setDeletingTopic(null);
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Topic</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete {deletingTopic?.name}? This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setIsDeleteDialogOpen(false);
                setDeletingTopic(null);
              }}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button 
              variant="destructive"
              onClick={() => handleDeleteTopic(deletingTopic?.id)}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Loader2 className="h-4 w-4 animate-spin mr-2" />
              ) : (
                'Delete Topic'
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TopicsManagement;