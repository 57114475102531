// src/lib/hooks/useFirebaseData.js
import { useState, useEffect } from 'react';
import { 
  collection, 
  query, 
  getDocs, 
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  limit,
  where,
  serverTimestamp
} from 'firebase/firestore';
import { auth, db } from '../firebase';
import { useAuth } from '../../contexts/AuthContext';

export const useCompanies = () => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let unsubscribe;

    try {
      const companiesRef = collection(db, 'companies');
      unsubscribe = onSnapshot(
        companiesRef, 
        (snapshot) => {
          const companiesData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          console.log('Companies data loaded:', companiesData);
          setCompanies(companiesData);
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching companies:", error);
          setError(error);
          setLoading(false);
        }
      );
    } catch (err) {
      console.error("Error setting up companies listener:", err);
      setError(err);
      setLoading(false);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const addCompany = async (companyData) => {
    if (!auth.currentUser) {
      throw new Error('Must be logged in to add a company');
    }

    try {
      console.log('Adding company with data:', companyData);
      console.log('Current user:', auth.currentUser.uid);

      const docRef = await addDoc(collection(db, 'companies'), {
        ...companyData,
        createdBy: auth.currentUser.uid,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });

      console.log('Company added successfully with ID:', docRef.id);
      return docRef.id;
    } catch (error) {
      console.error('Detailed add error:', {
        code: error.code,
        message: error.message,
        details: error
      });
      throw error;
    }
  };

  const updateCompany = async (id, companyData) => {
    if (!auth.currentUser) {
      throw new Error('Must be logged in to update a company');
    }

    try {
      console.log('Updating company:', { id, data: companyData });
      
      const companyRef = doc(db, 'companies', id);
      await updateDoc(companyRef, {
        ...companyData,
        updatedBy: auth.currentUser.uid,
        updatedAt: serverTimestamp()
      });

      console.log('Company updated successfully');
    } catch (error) {
      console.error('Detailed update error:', {
        code: error.code,
        message: error.message,
        details: error
      });
      throw error;
    }
  };

  const deleteCompany = async (id) => {
    if (!auth.currentUser) {
      throw new Error('Must be logged in to delete a company');
    }

    try {
      console.log('Deleting company:', id);
      
      const companyRef = doc(db, 'companies', id);
      await deleteDoc(companyRef);

      console.log('Company deleted successfully');
    } catch (error) {
      console.error('Detailed delete error:', {
        code: error.code,
        message: error.message,
        details: error
      });
      throw error;
    }
  };

  return {
    companies,
    loading,
    error,
    addCompany,
    updateCompany,
    deleteCompany
  };
};

export const useDashboardStats = () => {
  const [stats, setStats] = useState({
    companies: 0,
    taps: 0,
    users: 0,
    recentActivity: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        // Get collections references
        const [companiesSnap, tapsSnap, usersSnap] = await Promise.all([
          getDocs(collection(db, 'companies')),
          getDocs(collection(db, 'taps')),
          getDocs(collection(db, 'users'))
        ]);

        // Get recent activity
        const recentTapsQuery = query(
          collection(db, 'taps'),
          orderBy('createdAt', 'desc'),
          limit(5)
        );
        const recentTapsSnap = await getDocs(recentTapsQuery);
        
        const recentActivity = recentTapsSnap.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            createdAt: data.createdAt?.toDate?.() || new Date() // Handle Timestamp conversion
          };
        });

        setStats({
          companies: companiesSnap.size,
          taps: tapsSnap.size,
          users: usersSnap.size,
          recentActivity
        });
        setLoading(false);
      } catch (err) {
        console.error("Error fetching dashboard stats:", err);
        setError(err);
        setLoading(false);
      }
    };

    fetchStats();

    // Set up real-time listeners
    const unsubscribers = [];

    try {
      // Companies listener
      const companiesUnsubscribe = onSnapshot(
        collection(db, 'companies'),
        (snapshot) => {
          setStats(prev => ({ ...prev, companies: snapshot.size }));
        },
        (error) => console.error("Companies listener error:", error)
      );
      unsubscribers.push(companiesUnsubscribe);

      // Taps listener
      const tapsUnsubscribe = onSnapshot(
        collection(db, 'taps'),
        (snapshot) => {
          setStats(prev => ({ ...prev, taps: snapshot.size }));
        },
        (error) => console.error("Taps listener error:", error)
      );
      unsubscribers.push(tapsUnsubscribe);

      // Users listener
      const usersUnsubscribe = onSnapshot(
        collection(db, 'users'),
        (snapshot) => {
          setStats(prev => ({ ...prev, users: snapshot.size }));
        },
        (error) => console.error("Users listener error:", error)
      );
      unsubscribers.push(usersUnsubscribe);

      // Recent activity listener
      const recentActivityUnsubscribe = onSnapshot(
        query(collection(db, 'taps'), orderBy('createdAt', 'desc'), limit(5)),
        (snapshot) => {
          const activity = snapshot.docs.map(doc => {
            const data = doc.data();
            return {
              id: doc.id,
              ...data,
              createdAt: data.createdAt?.toDate?.() || new Date()
            };
          });
          setStats(prev => ({ ...prev, recentActivity: activity }));
        },
        (error) => console.error("Recent activity listener error:", error)
      );
      unsubscribers.push(recentActivityUnsubscribe);

    } catch (err) {
      console.error("Error setting up realtime listeners:", err);
      setError(err);
    }

    // Cleanup function
    return () => {
      console.log('Cleaning up dashboard listeners');
      unsubscribers.forEach(unsubscribe => unsubscribe());
    };
  }, []);

  return { stats, loading, error };
};


// Add this to src/lib/hooks/useFirebaseData.js

export const useTopics = () => {
    const [topics, setTopics] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const topicsRef = collection(db, 'topics');
      const unsubscribe = onSnapshot(
        topicsRef, 
        (snapshot) => {
          const topicsData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          console.log('Topics data loaded:', topicsData);
          setTopics(topicsData);
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching topics:", error);
          setError(error);
          setLoading(false);
        }
      );
  
      return () => unsubscribe();
    }, []);
  
    const addTopic = async (topicData) => {
      if (!auth.currentUser) {
        throw new Error('Must be logged in to add a topic');
      }
  
      try {
        console.log('Adding topic with data:', topicData);
        
        const docRef = await addDoc(collection(db, 'topics'), {
          ...topicData,
          createdBy: auth.currentUser.uid,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        });
  
        console.log('Topic added successfully:', docRef.id);
        return docRef.id;
      } catch (error) {
        console.error('Error adding topic:', error);
        throw error;
      }
    };
  
    const updateTopic = async (id, topicData) => {
      if (!auth.currentUser) {
        throw new Error('Must be logged in to update a topic');
      }
  
      try {
        console.log('Updating topic:', { id, data: topicData });
        
        const topicRef = doc(db, 'topics', id);
        await updateDoc(topicRef, {
          ...topicData,
          updatedBy: auth.currentUser.uid,
          updatedAt: serverTimestamp()
        });
  
        console.log('Topic updated successfully');
      } catch (error) {
        console.error('Error updating topic:', error);
        throw error;
      }
    };
  
    const deleteTopic = async (id) => {
      if (!auth.currentUser) {
        throw new Error('Must be logged in to delete a topic');
      }
  
      try {
        console.log('Deleting topic:', id);
        
        const topicRef = doc(db, 'topics', id);
        await deleteDoc(topicRef);
  
        console.log('Topic deleted successfully');
      } catch (error) {
        console.error('Error deleting topic:', error);
        throw error;
      }
    };
  
    return {
      topics,
      loading,
      error,
      addTopic,
      updateTopic,
      deleteTopic
    };
  };

  // Add to src/lib/hooks/useFirebaseData.js

  export const useTaps = () => {
    const [taps, setTaps] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
        const tapsRef = collection(db, 'taps');
        const unsubscribe = onSnapshot(
          tapsRef, 
          (snapshot) => {
            const tapsData = snapshot.docs.map(doc => {
              const data = doc.data();
              return {
                id: doc.id,
                ...data,
                chapters: data.chapters?.map(chapter => ({
                  ...chapter,
                  frames: chapter.frames?.map(frame => ({
                    ...frame,
                    // Ensure proper type casing and structure
                    type: frame.type?.toUpperCase(),
                    // Only include photo/video field based on type
                    ...(frame.type?.toUpperCase() === 'PHOTO' && { photo: frame.photo }),
                    ...(frame.type?.toUpperCase() === 'VIDEO' && { video: frame.video })
                  })) || []
                })) || [],
                createdAt: data.createdAt?.toDate?.() || null,
                updatedAt: data.updatedAt?.toDate?.() || null
              };
            });
            setTaps(tapsData);
            setLoading(false);
          },
          (error) => {
            console.error("Error fetching taps:", error);
            setError(error);
            setLoading(false);
          }
        );
    
        return () => unsubscribe();
    }, []);
  
    const addTap = async (tapData) => {
      if (!auth.currentUser) {
        throw new Error('Must be logged in to add a tap');
      }
  
      try {
        console.log('Adding tap with data:', tapData);
        
        // Ensure proper structure for new tap
        const formattedTapData = {
          ...tapData,
          creatorId: auth.currentUser.uid,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          chapters: tapData.chapters?.map(chapter => ({
            ...chapter,
            chapterId: chapter.chapterId || crypto.randomUUID(), // Ensure chapter ID
            creatorId: auth.currentUser.uid,
            creationDate: serverTimestamp(),
            frames: chapter.frames?.map(frame => ({
              ...frame,
              id: frame.id || crypto.randomUUID(),
              type: frame.type?.toUpperCase(),
              createdAt: serverTimestamp(),
              // Only include relevant media field based on type
              ...(frame.type?.toUpperCase() === 'PHOTO' && { photo: frame.photo }),
              ...(frame.type?.toUpperCase() === 'VIDEO' && { video: frame.video })
            })) || []
          })) || []
        };
  
        const docRef = await addDoc(collection(db, 'taps'), formattedTapData);
        console.log('Tap added successfully:', docRef.id);
        return docRef.id;
      } catch (error) {
        console.error('Error adding tap:', error);
        throw error;
      }
    };
  
    const updateTap = async (id, tapData) => {
      console.log('updateTap called with data:', tapData);
      try {
        // Create a clean version of the data
        const cleanData = {
          ...tapData,
          // Convert dates to proper format
          chapters: tapData.chapters?.map(chapter => ({
            ...chapter,
            creationDate: chapter.creationDate,
            frames: chapter.frames?.map(frame => ({
              ...frame,
              createdAt: frame.createdAt
            }))
          })),
          // Ensure serverTimestamp is properly created
          updatedAt: serverTimestamp()
        };
    
        console.log('Cleaned data to be sent:', cleanData);
        
        const tapRef = doc(db, 'taps', id);
        await updateDoc(tapRef, cleanData);
      } catch (error) {
        console.error('Detailed update error:', {
          code: error.code,
          message: error.message,
          details: error
        });
        throw error;
      }
    };
  
    const deleteTap = async (id) => {
      if (!auth.currentUser) {
        throw new Error('Must be logged in to delete a tap');
      }
  
      try {
        console.log('Deleting tap:', id);
        const tapRef = doc(db, 'taps', id);
        await deleteDoc(tapRef);
        console.log('Tap deleted successfully');
      } catch (error) {
        console.error('Error deleting tap:', error);
        throw error;
      }
    };
  
    return {
      taps,
      loading,
      error,
      addTap,
      updateTap,
      deleteTap
    };
};

  // In your useFirebaseData.js

export const useUsers = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { userRole, userData } = useAuth();
  
    useEffect(() => {
      try {
        const usersRef = collection(db, 'users');
        let q;
  
        // Filter by company if not admin
        if (userRole === 'ADMIN') {
          q = query(usersRef);
        } else {
          q = query(
            usersRef,
            where('companyInfo.companyCode', '==', userData?.companyInfo?.companyCode)
          );
        }
  
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const usersData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setUsers(usersData);
          setLoading(false);
        }, (err) => {
          console.error('Error fetching users:', err);
          setError(err);
          setLoading(false);
        });
  
        return () => unsubscribe();
      } catch (err) {
        console.error('Error setting up users listener:', err);
        setError(err);
        setLoading(false);
      }
    }, [userRole, userData?.companyInfo?.companyCode]);
  
    const addUser = async (userData) => {
      if (!auth.currentUser) throw new Error('Not authenticated');
      
      try {
        const newUserData = {
          ...userData,
          companyInfo: {
            companyCode: userData.companyCode || auth.currentUser.companyInfo?.companyCode,
            companyRole: userData.companyRole || 'EMPLOYEE',
            jobType: userData.jobType || ''
          },
          createdBy: auth.currentUser.uid,
          createdAt: serverTimestamp()
        };
  
        const docRef = await addDoc(collection(db, 'users'), newUserData);
        return docRef.id;
      } catch (error) {
        console.error('Error adding user:', error);
        throw error;
      }
    };
  
    const updateUser = async (id, userData) => {
      if (!auth.currentUser) throw new Error('Not authenticated');
      
      try {
        const userRef = doc(db, 'users', id);
        await updateDoc(userRef, {
          ...userData,
          updatedBy: auth.currentUser.uid,
          updatedAt: serverTimestamp()
        });
      } catch (error) {
        console.error('Error updating user:', error);
        throw error;
      }
    };
  
    const deleteUser = async (id) => {
      if (!auth.currentUser) throw new Error('Not authenticated');
      
      try {
        const userRef = doc(db, 'users', id);
        await deleteDoc(userRef);
      } catch (error) {
        console.error('Error deleting user:', error);
        throw error;
      }
    };
  
    return {
      users,
      loading,
      error,
      addUser,
      updateUser,
      deleteUser
    };
  };

  export const useChapters = () => {
    const [chapters, setChapters] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const unsubscribe = onSnapshot(
        collection(db, 'chapters'),
        (snapshot) => {
          const chaptersData = snapshot.docs.map((doc) => ({
            id: doc.chapterId,
            ...doc.data(),
            createdAt: doc.data().createdAt?.toDate?.() || null,
          }));
          console.log('Chapters data loaded:', chaptersData);
          setChapters(chaptersData);
          setLoading(false);
        },
        (err) => {
          console.error('Error fetching chapters:', err);
          setError(err);
          setLoading(false);
        }
      );
  
      return () => unsubscribe();
    }, []);
  
    const addChapter = async (chapterData) => {
      if (!auth.currentUser) {
        throw new Error('Must be logged in to add a chapter');
      }
  
      try {
        console.log('Adding chapter with data:', chapterData);
        
        const docRef = await addDoc(collection(db, 'chapters'), {
          ...chapterData,
          createdBy: auth.currentUser.uid,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        });
  
        console.log('Chapter added successfully:', docRef.id);
        return docRef.id;
      } catch (error) {
        console.error('Error adding chapter:', error);
        throw error;
      }
    };
  
    const updateChapter = async (id, chapterData) => {
      if (!auth.currentUser) {
        throw new Error('Must be logged in to update a chapter');
      }
  
      try {
        console.log('Updating chapter:', { id, data: chapterData });
        
        const chapterRef = doc(db, 'chapters', id);
        await updateDoc(chapterRef, {
          ...chapterData,
          updatedBy: auth.currentUser.uid,
          updatedAt: serverTimestamp()
        });
  
        console.log('Chapter updated successfully');
      } catch (error) {
        console.error('Error updating chapter:', error);
        throw error;
      }
    };
  
    const deleteChapter = async (id) => {
      if (!auth.currentUser) {
        throw new Error('Must be logged in to delete a chapter');
      }
  
      try {
        console.log('Deleting chapter:', id);
        
        const chapterRef = doc(db, 'chapters', id);
        await deleteDoc(chapterRef);
  
        console.log('Chapter deleted successfully');
      } catch (error) {
        console.error('Error deleting chapter:', error);
        throw error;
      }
    };
  
    return {
      chapters,
      loading,
      error,
      addChapter,
      updateChapter,
      deleteChapter
    };
  };
  
  export const useFrames = () => {
    const [frames, setFrames] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const unsubscribe = onSnapshot(
        collection(db, 'frames'),
        (snapshot) => {
          const framesData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt?.toDate?.() || null,
          }));
          console.log('Frames data loaded:', framesData);
          setFrames(framesData);
          setLoading(false);
        },
        (err) => {
          console.error('Error fetching frames:', err);
          setError(err);
          setLoading(false);
        }
      );
  
      return () => unsubscribe();
    }, []);
  
    const addFrame = async (frameData) => {
      if (!auth.currentUser) {
        throw new Error('Must be logged in to add a frame');
      }
  
      try {
        console.log('Adding frame with data:', frameData);
        
        const docRef = await addDoc(collection(db, 'frames'), {
          ...frameData,
          createdBy: auth.currentUser.uid,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        });
  
        console.log('Frame added successfully:', docRef.id);
        return docRef.id;
      } catch (error) {
        console.error('Error adding frame:', error);
        throw error;
      }
    };
  
    const updateFrame = async (id, frameData) => {
      if (!auth.currentUser) {
        throw new Error('Must be logged in to update a frame');
      }
  
      try {
        console.log('Updating frame:', { id, data: frameData });
        
        const frameRef = doc(db, 'frames', id);
        await updateDoc(frameRef, {
          ...frameData,
          updatedBy: auth.currentUser.uid,
          updatedAt: serverTimestamp()
        });
  
        console.log('Frame updated successfully');
      } catch (error) {
        console.error('Error updating frame:', error);
        throw error;
      }
    };
  
    const deleteFrame = async (id) => {
      if (!auth.currentUser) {
        throw new Error('Must be logged in to delete a frame');
      }
  
      try {
        console.log('Deleting frame:', id);
        
        const frameRef = doc(db, 'frames', id);
        await deleteDoc(frameRef);
  
        console.log('Frame deleted successfully');
      } catch (error) {
        console.error('Error deleting frame:', error);
        throw error;
      }
    };
  
    return {
      frames,
      loading,
      error,
      addFrame,
      updateFrame,
      deleteFrame
    };
  };